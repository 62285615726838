body {
  font-family: "Barlow", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0c0c27;
}

.mapboxgl-popup .mapboxgl-popup-content {
  padding: 0;
  border-radius: 0;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.mapboxgl-popup .mapboxgl-popup-content .icon svg {
  width: 35px;
}

.mapboxgl-popup-tip {
  display: block;
}

.mapboxgl-popup .mapboxgl-popup-close-button {
  font-size: 16px;
  background: #333;
  color: #fff;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  line-height: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bg-darkblue {
  background-color: #0c0c27;
  background-color: #060637;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: rgba(#fff, 0);
  width: 1px;
  padding: 1px 0;
}

::-webkit-scrollbar-thumb {
  background: rgba(192, 206, 213, 0.4);
  border-radius: 10px;
}
